.HomePageWrapper{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/backgroundimage.jpg");
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.formWrapper{
    background: #282a36;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    height: 400px;
    max-width: 80%;
    color: white;
}

.formWrapper > img{
    height: 20vh;
    width: 30vw;
}

.footer{
    color: white;
    position: fixed;
    bottom: 0;
}

.footer a{
    color: rgb(108, 238, 188);
    text-decoration: none;
}
.footer a:hover{
    color: crimson;
}

.inputGroup{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.mainLabel{
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5vmax;
    color: rgb(95, 235, 181);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.inputBox{
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    margin-bottom: 14px;
    background: #eee;
    font-size: 1.2vmax;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.btn{
    border: none;
    padding: 7px;
    border-radius: 5px;
    font-size: 1.5vmax;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    background-color: rgb(0, 245, 151);
    color: crimson;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.joinBtn{
    width: 100px;
    margin-left: auto;
}
.joinBtn:hover{
    background-color: crimson;
    color: white;
}

.inputGroup > span{
    margin: 0 auto ;
    margin-top: 20px;
}

.inputGroup > span > a{
    font-weight: 600;
    text-decoration: none;
    color: rgb(50, 189, 142);
}

.inputGroup > span > a:hover{
    color: crimson;
}



@media screen and ( max-width:600px ) {
    .formWrapper > img{
        width: 70vw;
    }
    .mainLabel{
        font-size: 2.5vmax;
    }
    .inputBox{
        font-size: 2vmax;
        font-weight: 700;
    }
    .Btn{
        font-size: 2.5vmax;
        font-weight: 800;
    }
}