::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track{
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb{
    background: #888 ;
}
::-webkit-scrollbar:horizontal{
    height: 5px; 
  }
::-webkit-scrollbar-thumb:hover{
    background: crimson;
}